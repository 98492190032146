import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/views/components/Layout";
const routes = [
    {
        path: '/',
        redirect: "/home" //"/lanhu_jiagefangan"
    },

    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import("@/views/home/index2.vue"),
                meta: {
                    title: "首頁",
                    name: 'home',
                }
            },
            {
                path: 'chanping',
                name: 'chanping',
                component: () => import("@/views/chanping/index.vue"),
                meta: {
                    title: "產品特點",
                    name: 'chanping',
                }
            },
            {
                path: 'jiage',
                name: 'jiage',
                component: () => import("@/views/jiage/index.vue"),
                meta: {
                    title: "價格方案",
                    name: 'jiage',
                }
            },
            {
                path: 'yuyue',
                name: 'yuyue',
                component: () => import("@/views/yuyue/index.vue"),
                meta: {
                    title: "預約方案",
                    name: 'yuyue',
                }
            },
            {
                path: 'xiazai',
                name: 'xiazai',
                component: () => import("@/views/xiazai/index.vue"),
                meta: {
                    title: "下載",
                    name: 'xiazai',
                }
            },
            {
                path: 'zhifu',
                name: 'zhifu',
                component: () => import("@/views/jiejue/zhifu.vue"),
                meta: {
                    title: "支付行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'ruanti',
                name: 'ruanti',
                component: () => import("@/views/jiejue/ruanti.vue"),
                meta: {
                    title: "行業應用軟體行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'lvshi',
                name: 'lvshi',
                component: () => import("@/views/jiejue/lvshi.vue"),
                meta: {
                    title: "律所行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'gongyinglian',
                name: 'gongyinglian',
                component: () => import("@/views/jiejue/gongyinglian.vue"),
                meta: {
                    title: "跨境供應鏈行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'zhengwu',
                name: 'zhengwu',
                component: () => import("@/views/jiejue/zhengwu.vue"),
                meta: {
                    title: "銀行、政企服務行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'dichan',
                name: 'dichan',
                component: () => import("@/views/jiejue/dichan.vue"),
                meta: {
                    title: "地產行業",
                    name: 'jiejue',
                }
            },
            {
                path: 'zhengjietong',
                name: 'zhengjietong',
                component: () => import("@/views/zhengjietong/index.vue"),
                meta: {
                    title: "證捷通",
                    name: 'zhengjietong',
                }
            },
        ]
    },

  /*{
    path: '/home',
    name: 'home',
    component: () => import("@/views/home/index2.vue"),
    meta: {
      title: "首頁",
      name: 'home',
    }
  },*/
    /*{
        path: '/zhifu',
        name: 'zhifu',
        component: () => import("@/views/jiejue/zhifu.vue"),
        meta: {
            title: "支付行業",
            name: 'jiejue',
        }
    },
    {
        path: '/ruanti',
        name: 'ruanti',
        component: () => import("@/views/jiejue/ruanti.vue"),
        meta: {
            title: "行業應用軟體行業",
            name: 'jiejue',
        }
    },
    {
        path: '/lvshi',
        name: 'lvshi',
        component: () => import("@/views/jiejue/lvshi.vue"),
        meta: {
            title: "律所行業",
            name: 'jiejue',
        }
    },
    {
        path: '/gongyinglian',
        name: 'gongyinglian',
        component: () => import("@/views/jiejue/gongyinglian.vue"),
        meta: {
            title: "跨境供應鏈行業",
            name: 'jiejue',
        }
    },
    {
        path: '/zhengwu',
        name: 'zhengwu',
        component: () => import("@/views/jiejue/zhengwu.vue"),
        meta: {
            title: "銀行、政企服務行業",
            name: 'jiejue',
        }
    },
    {
        path: '/dichan',
        name: 'dichan',
        component: () => import("@/views/jiejue/dichan.vue"),
        meta: {
            title: "地產行業",
            name: 'jiejue',
        }
    },*/
    /*{
        path: '/jiejue',
        name: 'jiejue',
        component: () => import("@/views/jiejue/index.vue"),
        meta: {
            title: "解決方案",
            name: 'jiejue',
        }
    },*/
    /*{
        path: '/chanping',
        name: 'chanping',
        component: () => import("@/views/chanping/index.vue"),
        meta: {
            title: "產品特點",
            name: 'chanping',
        }
    },
    {
        path: '/jiage',
        name: 'jiage',
        component: () => import("@/views/jiage/index.vue"),
        meta: {
            title: "價格方案",
            name: 'jiage',
        }
    },
    {
        path: '/yuyue',
        name: 'yuyue',
        component: () => import("@/views/yuyue/index.vue"),
        meta: {
            title: "預約方案",
            name: 'yuyue',
        }
    },
    {
        path: '/xiazai',
        name: 'xiazai',
        component: () => import("@/views/xiazai/index.vue"),
        meta: {
            title: "下載",
            name: 'xiazai',
        }
    }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 切换路由时滚动条置顶
router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router
