let BASE_API = "http://192.168.100.78:8000/api";

// 打包不用写死域名
if (process.env.NODE_ENV === 'production') {
    BASE_API = "/api"
}

module.exports = {
  BASE_API
}
